<template functional>
  <div class="pm-text-center">
    {{ infinite }}
    <VListLoadMore v-if="props.infinite" />
    <VListPagination v-else class="pagination">
      <template #prev="{prev,hasPrev}">
        <button :disabled="!hasPrev" @click="prev()">
          <Icon class="pm-w-5" name="MdiChevronLeft" />
        </button>
      </template>

      <template #page="{change,value,isActive}">
        <p v-if="isActive">{{ value }}</p>
        <button v-else @click="change(value)">{{ value }}</button>
      </template>

      <template #next="{next,hasNext}">
        <button :disabled="!hasNext" @click="next()">
          <Icon class="pm-w-5" name="MdiChevronRight" />
        </button>
      </template>
    </VListPagination>
    <VListCounter class="pm-text-xs pm-text-gray-500 pm-mt-2" />
  </div>
</template>

<script>
export default {
  props: {
    infinite: Boolean,
  },
};
</script>

<style lang="scss">
.pagination {
  @apply pm-inline-flex pm-flex-nowrap pm-justify-center pm-border pm-border-gray-200 pm-divide-gray-200 pm-divide-x pm-rounded-md;
  button,
  p {
    @apply pm-w-10 pm-h-10 pm-flex pm-justify-center pm-items-center pm-text-center pm-font-bold pm-text-gray-500;
  }
  button {
    outline: none !important;
    @apply hover:pm-bg-gray-100;

    &:disabled {
      @apply pm-cursor-not-allowed pm-pointer-events-none;
    }
  }
  p {
    @apply pm-text-primary-500;
  }
}
</style>
